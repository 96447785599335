import { IconExternalLink, IconCopy } from "@tabler/icons-react";
import { Button } from "components/button";
import { Radio } from "components/checkbox/Radio";
import { Collapse } from "components/collapse/Collapse";
import { FeaturedIcon } from "components/featuredIcon/FeaturedIcon";
import {
	showErrorNotification,
	showSuccessNotification,
} from "components/notifications/events";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "../../../../common/components/atoms/typography/Text";
import { Dialog } from "components/dialog/Dialog";
import { DialogActions } from "components/dialog/DialogActions";
import { DialogBody } from "components/dialog/DialogBody";
import { DialogContent } from "components/dialog/DialogContent";
import { DialogTitle } from "components/dialog/DialogTitle";
import { DialogTrigger } from "components/dialog/DialogTrigger";
import { ConnectorButton } from "../ConnectorButton";
import fortnoxLogo from "./fortnox-logo.png";
import { Anchor } from "components/anchor/Anchor";
import { useLazyGetFortnoxAuthorizationUrlQuery } from "src/common/service/fusionHub/integrations/fortnoxApi";

export const FortnoxConnectorDialogContent = ({
	companyDomainId,
}: {
	companyDomainId: string;
}) => {
	const { t } = useTranslation();

	const [fortnoxValue, setFortnoxValue] = useState<"redirect" | "copy">(
		"redirect",
	);
	const [createLink, { isLoading: isCreatingUrl }] =
		useLazyGetFortnoxAuthorizationUrlQuery();

	return (
		<>
			<DialogTitle
				icon={
					<FeaturedIcon color="primary" size="lg" theme="light_circle">
						<IconExternalLink />
					</FeaturedIcon>
				}
			>
				{t("Add Fortnox data source")}
				<Text size="sm" color="text-grey-500" className="mt-2">
					{t(
						"In order to add a Fortnox data source a Fortnox user with the correct ",
					)}
					<Anchor
						target="_blank"
						href={t(
							"https://www.exopen.se/sv/knowledge/beh%C3%B6righeter-fortnox",
						)}
					>
						{t("permissions")}
					</Anchor>
					{t(" has to sign in and approve the integration.")}
				</Text>
				<Text className="mt-2" weight="semibold">
					{t(
						"If you are a user in multiple companies in Fortnox, make sure you are signed in to the correct one. Otherwise, you will have to sign out and then sign in to the correct account in Fortnox in another window before you do this step.",
					)}
				</Text>
			</DialogTitle>
			<DialogBody>
				<label className="mb-5 flex items-center gap-2">
					<div className="shrink-0">
						<Radio
							name="fortnox-data-source-type"
							checked={fortnoxValue === "redirect"}
							onChange={(event) => {
								if (event.target.checked) {
									setFortnoxValue("redirect");
								}
							}}
						/>
					</div>
					<div>
						<Text size="sm" color="text-grey-700" weight="medium">
							{t("I want to do it myself")}
						</Text>
						<Text size="sm" color="text-grey-500" weight="regular">
							{t(
								"You'll be redirected to Fortnox to sign in and approve the integration",
							)}
						</Text>
					</div>
				</label>
				<label className="flex items-center gap-2">
					<div className="shrink-0">
						<Radio
							name="fortnox-data-source-type"
							checked={fortnoxValue === "copy"}
							onChange={(event) => {
								if (event.target.checked) {
									setFortnoxValue("copy");
								}
							}}
						/>
					</div>
					<div>
						<Text size="sm" color="text-grey-700" weight="medium">
							{t("I want to share a URL to a non-Exopen user")}
						</Text>
						<Text size="sm" color="text-grey-500" weight="regular">
							{t(
								"An external user will use the URL to sign in and approve the integration, copy a link and send it to a user with a Exopen account",
							)}
						</Text>
					</div>
				</label>
				<Collapse in={fortnoxValue === "copy"}>
					<Text size="sm" color="text-grey-500 pt-2" weight="regular">
						{t(
							"The link remains valid for 8 hours. After this period, you will need to generate a new one.",
						)}
					</Text>
				</Collapse>
			</DialogBody>
			<DialogActions withCancelButton>
				{fortnoxValue === "redirect" ? (
					<Button
						icon={<IconExternalLink />}
						iconPosition="right"
						isLoading={isCreatingUrl}
						onClick={async () => {
							const result = await createLink({
								companyDomainId,
								successRedirectUrl: `${window.location.origin}/${companyDomainId}/administration/connectors/setup?autoResolve=true`,
								errorRedirectUrl: window.location.href,
							});

							if ("error" in result) {
								showErrorNotification({ message: t("Something failed...") });
							} else {
								location.href = result.data!.authorizationUrl;
							}
						}}
					>
						{t("Continue")}
					</Button>
				) : (
					<Button
						icon={<IconCopy />}
						isLoading={isCreatingUrl}
						onClick={async () => {
							const result = await createLink({
								companyDomainId,
								successRedirectUrl: `${window.location.origin}/fortnox-external-callback`,
								errorRedirectUrl: `${window.location.origin}/fortnox-external-callback`,
							});

							if ("error" in result) {
								showErrorNotification({ message: t("Something failed...") });
							} else {
								await navigator.clipboard.writeText(
									result.data!.authorizationUrl!,
								);
								showSuccessNotification({
									message: t("The link was copied to clipboard"),
								});
							}
						}}
					>
						{t("Copy Link")}
					</Button>
				)}
			</DialogActions>
		</>
	);
};

interface Props {
	companyDomainId: string;
}

export const AddFortnoxConnectorModal = ({ companyDomainId }: Props) => {
	return (
		<Dialog>
			<DialogTrigger asChild>
				<ConnectorButton logo={fortnoxLogo} label="Fortnox" />
			</DialogTrigger>
			<DialogContent size="md">
				<FortnoxConnectorDialogContent companyDomainId={companyDomainId} />
			</DialogContent>
		</Dialog>
	);
};
