import type { DomainPlan } from "@exopengithub/planning-api-shared";
import { useTranslation } from "react-i18next";
import { Select } from "../../../../common/components/atoms/select/Select.tsx";
import { useRenderComparisonPlanOption } from "../../Analysis/diagramControls/useRenderComparisonPlanOption.tsx";
import { arrayToRecordByKey } from "../../../../common/utils/array-utils/arrayToRecordByKey.ts";
import { PlanColorIndicator } from "../../Analysis/PlanColorIndicator.tsx";
import { IconX } from "@tabler/icons-react";

interface ComparisonPlansSelectorProps {
	plans: DomainPlan[];
	onChange: (plans: string[]) => void;
	comparisonPlans: string[];
	className?: string;
	currentPlanId?: string;
	disabled?: boolean;
	"aria-labelledby": string;
}

export const ComparisonPlansSelector = ({
	plans,
	onChange,
	className,
	currentPlanId,
	disabled,
	"aria-labelledby": ariaLabelledBy,
	comparisonPlans,
}: ComparisonPlansSelectorProps) => {
	const { t } = useTranslation();
	const handleSelectComparisonPlan = (value: string | null) => {
		if (value !== null) {
			onChange(Array.from(new Set([...comparisonPlans, value])));
		}
	};

	const planById = arrayToRecordByKey(plans, "id");

	return (
		<div className={className}>
			<div className="w-min">
				<Select
					withFilter
					disabled={disabled}
					placeholder={t("Select comparison plans")}
					onChange={handleSelectComparisonPlan}
					value={null}
					options={[
						...plans.filter((plan) => {
							return (
								!comparisonPlans.includes(plan.id) && plan.id !== currentPlanId
							);
						}),
					]
						.sort((a, b) => a.name.localeCompare(b.name))
						.map((plan) => {
							return { value: plan.id, label: plan.name };
						})}
					aria-labelledby={ariaLabelledBy}
					renderOption={useRenderComparisonPlanOption(planById)}
				/>
			</div>
			{comparisonPlans.length > 0 && (
				<div className="flex flex-wrap gap-2 pt-4">
					{comparisonPlans
						.filter((id) => {
							return Boolean(planById[id]);
						})
						.map((id) => {
							return (
								<div
									key={id}
									className="flex items-center gap-2 rounded-full border bg-white px-3 py-1"
								>
									<PlanColorIndicator float={planById[id].float} />
									{planById[id].name}
									<button
										onClick={() => {
											onChange(comparisonPlans.filter((id2) => id2 !== id));
										}}
										aria-label={t("Remove")}
										className="rounded-full p-1 hover:bg-gray-100"
									>
										<IconX size={18} className="text-gray-700" />
									</button>
								</div>
							);
						})}
				</div>
			)}
		</div>
	);
};
