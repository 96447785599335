import { createBrowserRouter, Outlet } from "react-router-dom";
import { ProtectedApp } from "../ProtectedApp.js";
import { SUPERADMIN, COMPANYADMIN } from "../common/service/users.js";
import { CompanyDashboard } from "../features/companyDashboard/CompanyDashboard.js";
import { BusinessCentralPump } from "../features/connector/addConnector/businessCentral/BusinessCentralPump.js";
import { XledgerPump } from "../features/connector/addConnector/xledger/XledgerPump.js";
import { DevToolsPage } from "../features/devTools/DevTools.js";
import { Reports } from "../features/ewrReports/EwrReports.js";
import { Home } from "../features/home/Home.js";
import { LegacyInvoice } from "../features/invoice/LegacyInvoice.js";
import { Sidebar } from "../features/sidebar/Sidebar.js";
import { AccessGroupTable } from "../features/usersAndGroups/AccessGroupTable.js";
import { UserTable } from "../features/usersAndGroups/UserTable.js";
import AuthRoute from "./AuthRoute.js";
import { CompanyRouteLayout } from "./CompanyRouteLayout.js";
import { AppFrame, MainFrame } from "./ProtectedRoutes.js";
import { lazy } from "../common/utils/lazy.js";
import { PlanPageWrapper } from "../features/planning/PlanPageWrapper.js";
import { GroupHierarchyCard } from "../features/data-perspectives/legal-entities/GroupHierarchyCard.js";
import { LegalEntitiesPage } from "../features/data-perspectives/legal-entities/LegalEntitiesPage.js";
import { BalanceSheetHierarchyPage } from "../features/data-perspectives/pages/BalanceSheetPage.js";
import { CostCenterHierarchyPage } from "../features/data-perspectives/pages/CostCenterHierarchyPage.js";
import { CustomerHierarchyPage } from "../features/data-perspectives/pages/CustomerHierarchyPage.js";
import { PlanningUsersPage } from "../features/planning/settings/users/PlanningUsersPage.js";
import { ConnectConnectorPage } from "../features/connector/connectConnector/ConnectConnectorPage.js";
import { AddVismaNetConnectorRedirect } from "../features/connector/addConnector/visma/AddVismaNetConnectorRedirect.js";
import { ActivateFortnoxPage } from "../features/connector/addConnector/fortnox/ActivateFortnoxPage.js";
import { AddFortnoxConnectorExternalRedirect } from "../features/connector/addConnector/fortnox/AddFortnoxConnectorExternalRedirect.js";
import { Documentation } from "../features/connector/connectors/Documentation.js";
import { IncomingBalancePage } from "../features/planning/incoming-balance/IncomingBalancePage.js";
import { GeneralSettingsPage } from "../features/planning/settings/GeneralSettingsPage.js";
import { PLANNING_DEFINITIONS_TAB_ID } from "./routerConstants.js";
import { MergeAccountingConnectorsPage } from "../features/connector/addConnector/merge-accounting/MergeAccountingConnectorsPage.js";
import { SettingsPage } from "../features/settings/SettingsPage.js";
import { CompanyDomainSettingsPage } from "../features/settings/CompanyDomainSettingsPage.js";
import { FortnoxExternalCallbackPage } from "../features/connector/addConnector/fortnox/FortnoxExternalCallbackPage.js";
import { hasFeature } from "src/featureFlagUtils.js";
import { PageNotFound } from "src/features/appError/PageNotFound.js";
import { RedirectLegacyPlanningPage } from "src/features/planning/components/RedirectLegacyPlanningPage.js";

const { CustomerInvoicesPage } = lazy(
	() => import("src/features/invoice/CustomerInvoicesPage.js"),
);
const { SupplierInvoicesPage } = lazy(
	() => import("src/features/invoice/SupplierInvoicesPage.js"),
);
const { FinancialStatementsPage } = lazy(
	() =>
		import("../features/data-perspectives/pages/FinancialStatementsPage.js"),
);
const { AccountManagementPage } = lazy(
	() => import("../features/data-perspectives/pages/AccountManagementPage.js"),
);
const { IncomeStatementHierarchyPage } = lazy(
	() => import("../features/data-perspectives/pages/IncomeStatementPage.js"),
);
const { ProductHierarchyPage } = lazy(
	() => import("../features/data-perspectives/pages/ProductHierarchyPage.js"),
);
const { ProjectHierarchyPage } = lazy(
	() => import("../features/data-perspectives/pages/ProjectHierarchyPage.js"),
);
const { SupplierHierarchyPage } = lazy(
	() => import("../features/data-perspectives/pages/SupplierHierarchyPage.js"),
);
const { CreatePlanPage } = lazy(
	() => import("../features/planning/plans/CreatePlanPage/CreatePlanPage.js"),
);
const { PlanDetailLayout } = lazy(
	() => import("../features/planning/PlanDetailLayout.js"),
);
const { PlanDetailPage } = lazy(
	() => import("../features/planning/tasks/TaskDetailPage/PlanDetailPage.js"),
);
const { PlanningOverviewPage } = lazy(
	() => import("../features/planning/plans/PlanningOverviewPage.js"),
);
const { AssumptionsTab } = lazy(
	() => import("../features/planning/tasks/TaskDetailPage/AssumptionsTab.js"),
);
const { DefinitionsTabs } = lazy(
	() => import("../features/planning/tasks/TaskDetailPage/DefinitionsTab.js"),
);
const { AssetRegisters } = lazy(
	() => import("../features/planning/modules/assets/AssetRegisters.js"),
);
const { FinanceRegisters } = lazy(
	() => import("../features/planning/modules/finance/FinanceRegisters.js"),
);
const { FinancialAssetTypes } = lazy(
	() =>
		import(
			"../features/planning/modules/finance/financialAssetType/FinancialAssetTypes.js"
		),
);
const { EmployeeGroupPage } = lazy(
	() =>
		import(
			"../features/planning/modules/employee/register/employeeGroups/EmployeeGroupPage.js"
		),
);
const { ConsultancyRegisters } = lazy(
	() =>
		import(
			"../features/planning/modules/consultancy/register/ConsultancyRegisters.js"
		),
);
const { ConsultantTypes } = lazy(
	() =>
		import(
			"../features/planning/modules/consultancy/register/consultant-types/ConsultantTypes.js"
		),
);
const { CustomerInvoicePage } = lazy(
	() => import("src/features/invoice/CustomerInvoicePage.js"),
);
const { SupplierInvoicePage } = lazy(
	() => import("src/features/invoice/SupplierInvoicePage.js"),
);
const { CopyPlanPage } = lazy(
	() => import("../features/planning/plans/CopyPlanPage.js"),
);
const { PlanningSettingsPage } = lazy(
	() => import("../features/planning/settings/PlanningSettingsPage.js"),
);
const { HolidaysPage } = lazy(
	() =>
		import("../features/planning/settings/general/holidays/HolidaysPage.js"),
);
const { CostCenters } = lazy(
	() => import("../features/planning/settings/dim/costCenters/CostCenters.js"),
);
const { Customers } = lazy(
	() => import("../features/planning/settings/dim/Customers.js"),
);
const { Products } = lazy(
	() => import("../features/planning/settings/dim/Products.js"),
);
const { Projects } = lazy(
	() => import("../features/planning/settings/dim/Projects.js"),
);
const { Suppliers } = lazy(
	() => import("../features/planning/settings/dim/Suppliers.js"),
);
const { Dimensions } = lazy(
	() => import("../features/planning/registers/Dimensions.js"),
);
const { DebitRateKeys } = lazy(
	() =>
		import(
			"../features/planning/modules/consultancy/register/debit-rate-keys/DebitRateKeys.js"
		),
);
const { AccountLinks } = lazy(
	() => import("../features/planning/registers/accountLink/AccountLinks"),
);
const { AssetTypes } = lazy(
	() => import("../features/planning/modules/assets/assetType/AssetTypes.js"),
);
const { DriversRegisterPage } = lazy(
	() =>
		import("../features/planning/modules/driver/registers/DriversRegisterPage"),
);
const { PersonnelAgreements } = lazy(
	() =>
		import(
			"../features/planning/modules/employee/register/personnelAgreement/PersonnelAgreements"
		),
);
const { VacationKeys } = lazy(
	() =>
		import(
			"../features/planning/modules/employee/register/vacationKeys/VacationKeys"
		),
);
const { ServiceLevelKeys } = lazy(
	() =>
		import(
			"../features/planning/modules/employee/register/serviceLevelKeys/ServiceLevelKeys"
		),
);
const { VatSettings } = lazy(
	() =>
		import("../features/planning/settings/general/vatSetting/VatSettings.js"),
);

const { AllocationKeys } = lazy(
	() => import("../features/planning/registers/allocationKeys/AllocationKeys"),
);
const { AccountRecipes } = lazy(
	() => import("../features/planning/registers/accountRecipes/AccountRecipes"),
);
const { AssetPage } = lazy(
	() => import("../features/planning/modules/assets/assetType/AssetPage.js"),
);
const { FinancialAssetPage } = lazy(
	() =>
		import(
			"../features/planning/modules/finance/financialAssetType/FinancialAssetPage.js"
		),
);
const { EmployeeRegisters } = lazy(
	() =>
		import(
			"../features/planning/modules/employee/register/EmployeeRegisters.js"
		),
);
const { EmployeePage } = lazy(
	() =>
		import("../features/planning/modules/employee/register/EmployeePage.js"),
);

const { DataLayerWrapper } = lazy(
	() => import("../features/dataLayer/DataLayerWrapper"),
);
const { DataLayerOverview } = lazy(
	() => import("../features/dataLayer/DataLayerOverview"),
);

const { DataLayerPage } = lazy(
	() => import("../features/dataLayer/DataLayerPage"),
);

const { Connectors } = lazy(
	() => import("../features/connector/connectors/Connectors"),
);
const { ConnectorsTable } = lazy(
	() =>
		import("../features/connector/connectors/ConnectorsTable/ConnectorsTable"),
);
const { AddConnector } = lazy(
	() => import("../features/connector/addConnector/AddConnector"),
);
const { LoadHistory } = lazy(
	() => import("../features/connector/connectors/LoadHistory/LoadHistory"),
);
const { UsersAndGroupsPage } = lazy(
	() => import("../features/usersAndGroups/UsersAndGroupsPage"),
);

const { ConsolidationSettingsPage } = lazy(
	() => import("../features/consolidation/pages/ConsolidationSettingsPage.js"),
);
const { SiePage } = lazy(() => import("../features/uploadSie/SiePage"));
const { SupportUsers } = lazy(
	() => import("../features/supportUsers/SupportUsers"),
);
const { PurchasePriceAllocationPage } = lazy(
	() => import("../features/consolidation/pages/PurchasePriceAllocationPage"),
);
const { PeAccountingPump } = lazy(
	() =>
		import(
			"../features/connector/addConnector/peAccounting/PeAccountingPump.js"
		),
);
const { TfsOfficePump } = lazy(
	() => import("../features/connector/addConnector/tfsOffice/TfsOfficePump.js"),
);
const { AddVismaNetConnectorPortal: VismaPump } = lazy(
	() =>
		import(
			"../features/connector/addConnector/visma/AddVismaNetConnectorPortal.js"
		),
);
const { AdminPlansPage } = lazy(
	() => import("../features/planning/admin/AdminPlansPage.js"),
);

const { AccountTypeEditPage } = lazy(
	() => import("../features/data-perspectives/pages/AccountTypeEditPage.js"),
);
const { VitecPump } = lazy(
	() => import("../features/connector/addConnector/vitec/VitecPump.js"),
);
const { VitecConnectorOverview } = lazy(
	() => import("../features/connector/vitec/VitecConnectorOverview.js"),
);
const { AddXeroConnectorPage } = lazy(
	() =>
		import("../features/connector/addConnector/xero/AddXeroConnectorPage.js"),
);
const { SiePreviewPage } = lazy(
	() => import("../features/connector/sie/SiePreviewPage.js"),
);
const { SieIntegrationPage } = lazy(
	() => import("../features/connector/sie/integration/IndexPage.js"),
);
const { SieIntegrationFileSummaryPage } = lazy(
	() => import("../features/connector/sie/integration/SummaryPage.js"),
);

export const router = createBrowserRouter([
	{
		path: "*",
		element: <PageNotFound />,
	},
	{
		path: "fortnox-external",
		element: <AddFortnoxConnectorExternalRedirect />,
	},
	{
		path: "fortnox-external-callback",
		element: <FortnoxExternalCallbackPage />,
	},
	{
		path: "/",
		element: <ProtectedApp />,
		children: [
			{ path: "fortnox", element: <ActivateFortnoxPage /> },
			{
				path: "company-domains/:companyDomainId",
				element: <CompanyRouteLayout sidebarOpen={false} />,
				children: [
					{
						path: "legal-entities/:legalEntityId",
						children: [
							{
								path: "customer-invoices",
								children: [
									{ index: true, element: <CustomerInvoicesPage /> },
									{ path: ":invoiceId", element: <CustomerInvoicePage /> },
								],
							},
							{
								path: "supplier-invoices",
								children: [
									{ index: true, element: <SupplierInvoicesPage /> },
									{ path: ":invoiceId", element: <SupplierInvoicePage /> },
								],
							},

							{
								path: "planning",
								element: <PlanPageWrapper />,
								children: [
									{
										path: "",
										element: <PlanningOverviewPage />,
									},
									{ path: "create-plan", element: <CreatePlanPage /> },
									{
										path: "plan/:planId",
										element: <PlanDetailLayout />,
										children: [
											{
												element: <PlanDetailPage />,
												children: [
													{ index: true, element: <AssumptionsTab /> },
													{
														path: "definitions",
														element: <DefinitionsTabs />,
														children: [
															{
																path: "account-recipes",
																element: <AccountRecipes />,
															},
															{
																path: "account",
																element: <AccountLinks />,
																id: "account",
															},
															{
																path: "accrual-keys",
																element: <AllocationKeys />,
															},
															{
																path: "incoming-balance",
																element: <IncomingBalancePage />,
															},
															{
																path: "driver",
																element: (
																	<DriversRegisterPage refType="driver" />
																),
																id: "driver",
															},
															{
																path: "asset",
																element: <AssetRegisters />,
																children: [
																	{
																		index: true,
																		element: <AssetTypes />,
																	},
																	{
																		path: "asset",
																		element: <AssetPage />,
																	},
																],
																id: "asset",
															},
															{
																path: "finance",
																element: <FinanceRegisters />,
																children: [
																	{
																		index: true,
																		element: <FinancialAssetTypes />,
																	},
																	{
																		path: "financial-asset",
																		element: <FinancialAssetPage />,
																	},
																],
																id: "finance",
															},
															{
																path: "employee_pp",
																element: <EmployeeRegisters />,
																children: [
																	{
																		index: true,
																		element: <EmployeePage />,
																	},
																	{
																		path: "personnel-agreements",
																		element: <PersonnelAgreements />,
																	},
																	{
																		path: "benefits",
																		element: (
																			<DriversRegisterPage refType="benefit" />
																		),
																	},
																	{
																		path: "vacation-keys",
																		element: <VacationKeys />,
																	},
																	{
																		path: "service-level-keys",
																		element: <ServiceLevelKeys />,
																	},
																	{
																		path: "employee-groups",
																		element: <EmployeeGroupPage />,
																	},
																],
																id: "employee_pp",
															},
															{
																path: "consultancy",
																element: <ConsultancyRegisters />,
																children: [
																	{
																		index: true,
																		element: <ConsultantTypes />,
																	},
																	{
																		path: "debit-rate-keys",
																		element: <DebitRateKeys />,
																	},
																],
																id: "consultancy",
															},
														],
														id: PLANNING_DEFINITIONS_TAB_ID,
													},
												],
											},

											{ path: "copy-plan", element: <CopyPlanPage /> },
											{
												path: "settings",
												element: <PlanningSettingsPage />,
												children: [
													{
														path: "general",
														element: <GeneralSettingsPage />,
													},
													{ path: "users", element: <PlanningUsersPage /> },
													{
														path: "dimensions",
														element: <Dimensions />,
														children: [
															{ index: true, element: <CostCenters /> },
															{ path: "customers", element: <Customers /> },
															{ path: "suppliers", element: <Suppliers /> },
															{ path: "projects", element: <Projects /> },
															{ path: "products", element: <Products /> },
														],
													},
													{
														path: "vat",
														element: <VatSettings />,
													},
													{
														path: "holidays",
														element: <HolidaysPage />,
													},
												],
											},
										],
									},
								],
							},
						],
					},
				],
			},
			{
				path: ":companyId",
				element: <CompanyRouteLayout />,
				children: [
					{ index: true, element: <CompanyDashboard /> },
					{
						path: "planning/*",
						element: <RedirectLegacyPlanningPage />,
					},
					{
						path: "datalayer",
						element: <AuthRoute Component={DataLayerWrapper} />,
						children: [
							{
								path: "",
								element: <DataLayerOverview />,
								children: [
									{
										index: true,
										element: <DataLayerPage />,
									},
								],
							},
						],
					},
					{ path: "admin/planning", element: <AdminPlansPage /> },
					{
						path: "administration/connectors",
						element: <AuthRoute Component={<Connectors />} />,
						children: [
							{
								index: true,
								element: <ConnectorsTable />,
							},
							{
								path: "documentation",
								element: <Documentation />,
							},
							{
								path: "history",
								element: <LoadHistory />,
							},
						],
					},
					{
						path: "administration/connectors/:system/configure",
						element: (
							<AuthRoute Component={<MergeAccountingConnectorsPage />} />
						),
					},
					{
						path: "administration/connectors/setup",
						element: <AuthRoute Component={<ConnectConnectorPage />} />,
					},
					{
						path: "administration/connectors/new",
						children: [
							{ index: true, element: <AddConnector /> },
							{ path: "visma", element: <VismaPump /> },
							{ path: "xledger", element: <XledgerPump /> },
							{ path: "businesscentral", element: <BusinessCentralPump /> },
							{ path: "24sevenoffice", element: <TfsOfficePump /> },
							{ path: "peaccounting", element: <PeAccountingPump /> },
							{
								path: "vitec",
								element: hasFeature("fusionHubVitecDataSources") ? (
									<VitecConnectorOverview />
								) : (
									<VitecPump />
								),
							},
							{ path: "xero", element: <AddXeroConnectorPage /> },
							{
								path: "sie",
								children: [
									{
										path: ":sourceId",
										element: <SiePreviewPage />,
									},
								],
							},
						],
					},
					{
						path: "administration/connectors/sie/:integrationId",
						children: [
							{
								index: true,
								element: <SieIntegrationPage />,
							},
							{
								path: ":fileId",
								element: <SieIntegrationFileSummaryPage />,
							},
						],
					},
					{
						path: "upload/sie/*",
						element: (
							<AuthRoute
								Component={<SiePage />}
								requiredRoles={COMPANYADMIN}
								requiredPermissions={["DataSieUpload"]}
							/>
						),
					},
					{
						path: "administration/reports",
						element: (
							<AuthRoute Component={Reports} requiredRoles={SUPERADMIN} />
						),
					},
					{
						path: "settings",
						element: (
							<AuthRoute
								Component={SettingsPage}
								requiredRoles={[...SUPERADMIN, ...COMPANYADMIN]}
							/>
						),
						children: [
							{ index: true, element: <CompanyDomainSettingsPage /> },
							{
								path: "users",
								element: <UsersAndGroupsPage />,
								children: [
									{ index: true, element: <UserTable /> },
									{ path: "groups", element: <AccessGroupTable /> },
								],
							},
						],
					},
					{
						path: "administration/hierarchy",
						element: (
							<AuthRoute requiredRoles={[...SUPERADMIN, ...COMPANYADMIN]} />
						),
						children: [
							{
								path: "legal-entities",
								element: <LegalEntitiesPage />,
								children: [
									{
										index: true,
										element: <GroupHierarchyCard />,
									},
								],
							},
							{
								path: "account",
								element: <AccountManagementPage />,
								children: [
									{
										path: "hierarchy",
										element: <FinancialStatementsPage />,
										children: [
											{
												index: true,
												element: <IncomeStatementHierarchyPage />,
											},
											{
												path: "balance-sheet",
												element: <BalanceSheetHierarchyPage />,
											},
										],
									},
									{
										path: "type",
										element: <AccountTypeEditPage />,
									},
								],
							},
							{
								path: "project",
								element: <ProjectHierarchyPage />,
							},
							{
								path: "costcenter",
								element: <CostCenterHierarchyPage />,
							},
							{
								path: "product",
								element: <ProductHierarchyPage />,
							},
							{
								path: "customer",
								element: <CustomerHierarchyPage />,
							},
							{
								path: "supplier",
								element: <SupplierHierarchyPage />,
							},
						],
					},
					{
						path: "administration/hierarchy2/*",
						element: (
							<AuthRoute
								Component={ConsolidationSettingsPage}
								requiredRoles={[...SUPERADMIN]}
							/>
						),
					},
					{
						path: "administration/purchase-price-allocation/*",
						element: (
							<AuthRoute
								Component={PurchasePriceAllocationPage}
								requiredRoles={[...SUPERADMIN]}
							/>
						),
					},
					{
						path: "administration/dev-tools/*",
						element: (
							<AuthRoute
								Component={DevToolsPage}
								requiredRoles={[...SUPERADMIN]}
							/>
						),
					},
					{
						path: "administration/support/users",
						element: (
							<AuthRoute Component={SupportUsers} requiredRoles={SUPERADMIN} />
						),
					},
				],
			},
			{
				path: "vismanet-redirect",
				element: (
					<AppFrame>
						<MainFrame>
							<AddVismaNetConnectorRedirect />
						</MainFrame>
					</AppFrame>
				),
			},
			{
				path: "storage/companies/:companyId/pump/:pumpId/CustomerInvoice/*",
				element: <LegacyInvoice />,
			},
			{
				path: "storage/companies/:companyId/pump/:pumpId/SupplierInvoice/*",
				element: <LegacyInvoice />,
			},
			{
				element: (
					<AppFrame>
						<Sidebar empty />
						<MainFrame>
							<Outlet />
						</MainFrame>
					</AppFrame>
				),
				children: [
					{
						index: true,
						element: <Home />,
					},
				],
			},
		],
	},
]);
