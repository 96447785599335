export const getSystemDisplayName = (type: string) => {
	switch (type) {
		case "VismaNet":
			return "Visma.net ERP";
		case "TfsOffice":
			return "24SevenOffice";
		case "ForecastPro":
			return "Forecast Pro";
		case "VITEC_CLOUD": // FH
		case "VitecCloud": // ETL
			return "Vitec";
		default:
			return type;
	}
};
